export function validateTitle(_, title) {
  const spaces = /\s{2,}/g;
  /* eslint-disable-next-line */
  const specialChars = /[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/?~]/;
  const specialWords = /продам|продается|сдам|продаю|продаем|сдаю|сдается|рассмотрю|рассмотрим|рассматриваем/g;

  if (specialChars.test(title)) {
    return Promise.reject(new Error("Заголовок не должен содержать специальные знаки и символы"));
  }

  if (specialWords.test(title?.toLowerCase())) {
    return Promise.reject(new Error("Не используйте слова или фразы «Продам», «Продается» и т.п."));
  }

  if (spaces.test(title)) {
    return Promise.reject(new Error("Уберите лишние пробелы в тексте"));
  }

  return Promise.resolve();
}

export function validateURL(_, url) {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator

  if (!url) {
    return Promise.resolve();
  }

  if (!urlPattern.test(url)) {
    return Promise.reject(new Error("Введите корректный формат ссылки, начинающийся с https://"));
  }

  return Promise.resolve();
}

export function calculatePaybackPeriod(props) {
  const { form } = props;

  if (!form) return;

  let { revenue = null, costs = null, profit = null } = props;

  const isChangingProfit = !!profit;
  revenue = revenue || form.getFieldValue(["sale", "month_revenue"]) || 0;
  costs = costs || form.getFieldValue(["sale", "month_costs"]) || 0;
  profit = profit || form.getFieldValue(["sale", "month_profit"]) || 0;

  const price = form.getFieldValue("price") || 0;
  const calculatedProfit = isChangingProfit ? profit : parseInt(revenue - costs) || 0;
  const calculatedPayback = parseInt(price / calculatedProfit) || 0;

  if (!isChangingProfit) {
    // Set form fields
    form.setFieldValue(["sale", "month_profit"], calculatedProfit < 0 ? 0 : calculatedProfit);
  }

  form.setFieldValue(["sale", "payback_period"], calculatedPayback < 0 ? 0 : calculatedPayback);
}

export function onPaybackAdjust(props) {
  let { form = null, payback = null, setPaybackAdjusted = null } = props;
  const profit = form.getFieldValue(["sale", "month_profit"]) || 0;
  const price = form.getFieldValue("price") || 0;
  const calculatedPayback = parseInt(price / profit) || 0;

  if (!form || !payback || !setPaybackAdjusted || calculatedPayback === 0) {
    return;
  }

  if (payback !== calculatedPayback) {
    return setPaybackAdjusted(true);
  }

  setPaybackAdjusted(false);
}

export const PRODUCT_STATUS_TITLES = {
  draft: "Черновик",
  published: "Опубликовано",
  unpublished: "Снято с публикации",
  in_review: "На модерации",
  rejected: "Отклонено",
  archived: "В архиве",
  deleted: "Удалено"
};

export const PRODUCT_TYPE_TITLES = {
  sale: "Бизнес",
  franchise: "Франшиза",
  investment: "Инвестиции"
};
